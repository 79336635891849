<template>
    <section class="invoice-preview-wrapper">
      <!-- {{ getPayments }} -->
      <!-- {{ getReceipts  }} -->
      <!-- Alert: No item found -->
      <b-alert
        variant="danger"
        :show="incomeData === undefined"
      >
        <h4 class="alert-heading">
          Error fetching income data
        </h4>
        <div class="alert-body">
          No income found with this income id. Check
          <b-link
            class="alert-link"
            :to="{ name: 'apps-income-list'}"
          >
            Income List
          </b-link>
          for other incomes.
        </div>
      </b-alert>
      
      <b-row
        class="invoice-preview"
      >
        
        <!-- Col: Left (Income Container) -->
        <b-col
          cols="12"
          xl="9"
          md="8"
        >
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <b-col cols="12">
              <div class="text-center my-3">
                <h1 class="">Income Statement</h1>
                <div v-if="churchData">
                  <p class="mb-0">{{ churchData.name }}</p>
                  <h5 class="mt-2 font-weight-bolder"> {{ (fromDate && toDate ? (new Date(fromDate).toDateString().substring(4))+' - '+(new Date(toDate).toDateString().substring(4)) : ['This Year', 'Last Year'].includes(rangeOptions.find(x=>x.value == date).label) ? 'Jan 01 '+date+' - '+'Dec 31 '+date : rangeOptions.find(x=>x.value == date).date+' - '+rangeOptions.find(x=>x.value == date).label) }} </h5>
                </div>
              </div>
            </b-col>
  
            <!-- Spacer -->
            <hr class="invoice-spacing">
            <b-row>
              <b-col cols="4">
                <div class="py-1 px-3">
                  <h5 class="font-weight-bolder">Income </h5>
                </div>
              </b-col>
              <b-col cols="4">
                
              </b-col>
              <b-col cols="4">
                <!-- <div class="py-1 px-3">
                  <h6 class="font-weight-bolder text-right"> {{ formatAmount(getReceipts.reduce((a, b) => a + b.amount, 0)) }} </h6>
                </div> -->
              </b-col>
            </b-row>
            <!--  -->
            <!-- {{ getReceipts.map(x => x.type) }} -->
            <!-- {{ getReceiptsType }} -->
            <b-row v-for="rev in getReceiptsType" :key="rev">
              <b-col cols="4">
                <div class=" pl-5">
                  <p class="font-weight-"> {{ rev.substring(0,1).toUpperCase()+rev.substring(1) }} </p>
                </div>
              </b-col>
              <b-col cols="4">
                
              </b-col>
              <b-col cols="4">
                <div class=" px-3">
                  <h6 class="font-weight- text-right"> {{ formatAmount(getReceipts.filter(x=>x.type == rev).reduce((a, b) => a + b.amount, 0)) }}</h6>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <div class="pl-5 px-3">
                  <hr class="mb-1" style="border-color: #fff">
                  <h6 class="font-weight-bolder">Gross Income: </h6>
                </div>
              </b-col>
              <b-col cols="4">
                
              </b-col>
              <b-col cols="4">
                <div class="px-3">
                  <hr class="mb-1">
                  <h6 class="font-weight-bolder text-right"> {{ formatAmount(getReceipts.reduce((a, b) => a + b.amount, 0)) }} </h6>
                </div>
              </b-col>
            </b-row>

            <b-row class="mt-3">
              <b-col cols="4">
                <div class="py-1 px-3">
                  <h5 class="font-weight-bolder">Expense </h5>
                </div>
              </b-col>
              <b-col cols="4">
                
              </b-col>
              <b-col cols="4">
                <!-- <div class="py-1 px-3">
                  <h6 class="font-weight-bolder text-right"> {{ formatAmount(600) }} </h6>
                </div> -->
              </b-col>
            </b-row>

            <b-row v-for="pay in getPaymentsCategory" :key="pay">
              <b-col cols="4">
                <div class=" pl-5">
                  <p class="font-weight-"> {{ pay.substring(0,1).toUpperCase()+pay.substring(1) }} </p>
                </div>
              </b-col>
              <b-col cols="4">
                
              </b-col>
              <b-col cols="4">
                <div class=" px-3">
                  <h6 class="font-weight- text-right"> {{ formatAmount(getPayments.filter(x=>x.category == pay).reduce((a, b) => a + b.amount, 0)) }}</h6>
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="4">
                <div class="pl-5 px-3">
                  <hr class="mb-1" style="border-color: #fff">
                  <h6 class="font-weight-bolder">Total Expense: </h6>
                </div>
              </b-col>
              <b-col cols="4">
                
              </b-col>
              <b-col cols="4">
                <div class="px-3">
                  <hr class="mb-1">
                  <h6 class="font-weight-bolder text-right"> {{ formatAmount(getPayments.reduce((a, b) => a + b.amount, 0)) }} </h6>
                </div>
              </b-col>
            </b-row>


            <b-row class="mt-3">
              <b-col cols="4">
                <div class="py-1 px-3">
                  <hr style="border-color: #fff">
                  <h5 class="font-weight-bolder">Net Income: </h5>
                </div>
              </b-col>
              <b-col cols="4">
                
              </b-col>
              <b-col cols="4">
                <div class="py-1 px-3">
                  <hr >
                  <h6 class="font-weight-bolder text-right"> {{ formatAmount(getReceipts.reduce((a, b) => a + b.amount, 0) - getPayments.reduce((a, b) => a + b.amount, 0)) }} </h6>
                  <hr >
                </div>
              </b-col>
            </b-row>
            
  
  
            <!-- Income Description: Total -->
            <b-card-body v-if="false" class="invoice-padding pb-0">
              <b-row>
                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <!-- <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Subtotal:
                      </p>
                      <p class="invoice-total-amount">
                        {{ records ? records.reduce((a, b) => a + b.amount, 0) : incomeData.amount }}
                      </p>
                    </div> -->
                    <!-- <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Discount:
                      </p>
                      <p class="invoice-total-amount">
                        $28
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Tax:
                      </p>
                      <p class="invoice-total-amount">
                        21%
                      </p>
                    </div> -->
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total:
                      </p>
                      <p class="invoice-total-amount">
                        <!-- {{ records.length > 0 ? formatAmount(recordData.totalAmount) : formatAmount(incomeData.amount) }} -->
                      </p>
                    </div>
                  </div>
                </b-col>
                <!-- Col: Sales Persion -->
                <!-- <b-col
                  v-if="incomeData"
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3"
                  order="2"
                  order-md="1"
                >
                  <b-card-text  class="mt-3">
                    <span class="font-weight-bold">By:</span>
                    <span class="ml-75">{{ incomeData.by }}</span>
                  </b-card-text>
                  <b-card-text v-if="incomeData.approved">
                    <span class="font-weight-bold">Approved By:</span>
                    <span class="ml-75">{{ incomeData.approved }}</span>
                  </b-card-text>
                  <b-card-text v-else-if="incomeData.cancelled">
                    <span class="font-weight-bold">Cancelled By:</span>
                    <span class="ml-75">{{ incomeData.cancelled }}</span>
                  </b-card-text>
                  <b-card-text v-if="incomeData.cancelReason">
                    <span class="font-weight-bold">Cancel Reason:</span>
                    <span class="ml-75">{{ incomeData.cancelReason }}</span>
                  </b-card-text>
                </b-col>
                <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-5"
                order="2"
                order-md="1"
                v-else>

            </b-col> -->
  
                
              </b-row>
            </b-card-body>
  
            <!-- Spacer -->
            <hr class="invoice-spacing">
  
            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">Note: </span>
              <span>It was a pleasure serving you and your team. Thank You!</span>
            </b-card-body>
          </b-card>
        </b-col>
  
        <!-- Right Col: Card -->
        <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions"
        >
          <b-card>
  
            <!-- Button: Send Income -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-toggle.sidebar-send-income
              variant="primary"
              class="mb-75"
              block
              :to="{ name: 'accounts-receipt-list' }"
            >
              View Receipts
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-toggle.sidebar-send-income
              variant="secondary"
              class="mb-75"
              block
              :to="{ name: 'accounts-payment-list' }"
            >
              View Payments
            </b-button>
  
            <!-- Button: DOwnload -->
            <!-- <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mb-75"
              block
            >
              Download
            </b-button> -->
  
            <!-- Button: Print -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mb-75"
              block
              @click="printIncome"
            >
              Print
            </b-button>
  
            <!-- Button: Edit -->
            <!-- <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mb-75"
              block
              :to="{ name: 'accounts-income-list', params: { id: $route.params.id } }"
            >
              Edit
            </b-button> -->
  
            <!-- Button: Add Income -->
            <!-- <b-button
              v-b-toggle.sidebar-income-add-income
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mb-75"
              block
            >
              Add Income
            </b-button> -->
          </b-card>

          <b-card>

            <b-row>
              <b-col
                cols="12"
                sm="12"
                md="12"
                class="mb-1"
              >
                <label>Range</label>
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="date"
                  :options="rangeOptions"
                  class="w-100"
                  :reduce="val => val.value"
                  :clearable="true"
                />
              </b-col>
              <b-col
                cols="12"
                sm="12"
                md="12"
                class="mb-1">
                <b-form-datepicker
                  v-model="fromDate"
                  placeholder="Date From"
                  class="mr-1"
                  today-button
                  reset-button
                  close-button
                ></b-form-datepicker>
              </b-col>
              <b-col
                cols="12"
                sm="12"
                md="12"
                class="mb-1">
                <b-form-datepicker
                  v-model="toDate"
                  placeholder="Date To"
                  class="mr-1"
                  today-button
                  reset-button
                  close-button
                ></b-form-datepicker>
              </b-col>
            </b-row>
            <!-- Button: Print -->
          </b-card>
        </b-col>
      </b-row>
  
      <!-- <income-sidebar-send-income /> -->
    </section>
  </template>
  
  <script>
  import { ref, onUnmounted, computed, watch } from '@vue/composition-api'
  import store from '@/store'
  import router from '@/router'
  import {
    BRow, BCol, BCard, BBadge, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BImg, BFormDatepicker
  } from 'bootstrap-vue'
  import Logo from '@core/layouts/components/Logo.vue'
  import Ripple from 'vue-ripple-directive'
  import incomeStoreModule from '../incomeStoreModule'
  // import IncomeSidebarSendIncome from '../incomeSidebarSend.vue'
  import { formatAmount } from '@core/comp-functions/formatAmount'
  import moment from "moment"
  import vSelect from 'vue-select'
  
  export default {
    directives: {
      Ripple,
      'b-toggle': VBToggle,
    },
    components: {
      BRow,
      BCol,
      BCard,
      BBadge,
      BCardBody,
      BTableLite,
      BCardText,
      BButton,
      BAlert,
      BLink,
      BImg,
      Logo,
      vSelect,
      BFormDatepicker
      // IncomeSidebarSendIncome,
    },
    setup() {
      const churchData = ref(null)
      const incomeData = ref(null)
      const records = ref([])
      const receipts = ref([])
      const payments = ref([])
      const recordData = ref(null)
      const action = ref(null)
      const date = ref(new Date().toISOString().substring(0,4))
      const fromDate = ref(null)
      const toDate = ref(null)
  
      const ACCOUNT_RECEIPT_STORE_MODULE_NAME = 'account-income'
  
      // Register module
      if (!store.hasModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME)) store.registerModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME, incomeStoreModule)
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME)
      })
  
      // if (router.currentRoute.params.id !== "0") {
  
      //   store.dispatch('account-income/fetchIncome', { id: router.currentRoute.params.id })
      //     .then(response => {
      //       incomeData.value = response.data.income
      //     })
      //     .catch(error => {
      //       if ([404, 400, 500].includes(error.response.status)) {
      //         receiptData.value = undefined
      //       }
      //     })
  
      //   store.dispatch('account-income/churchInfo')
      //     .then(response => {
      //       churchData.value = response.data
      //     })
      //     .catch(error => {
      //       if ([404, 400, 500].includes(error.response.status)) {
      //         receiptData.value = undefined
      //       }
      //     })
      // }

      // else if (router.currentRoute.params.action == 'preview' ) {

      //   store.dispatch('account-income/churchInfo')
      //     .then(response => {
      //       churchData.value = response.data
      //     })
      //     .catch(error => {
      //       if ([404, 400, 500].includes(error.response.status)) {
      //         churchData.value = undefined
      //       }
      //     }) 

      //   action.value = router.currentRoute.params.action

      //   // fetchReceipts()
      //   recordData.value = router.currentRoute.params.params

      //   const { incomes, totalAmount } = store.getters['account-income/getIncomes'](router.currentRoute.params.params)
      //   records.value =  incomes
      //   recordData.value.totalAmount = totalAmount

      //   console.log(router.currentRoute.params)
      //   console.log(records.value)


      // }

      // else if (router.currentRoute.params.action == 'approvals' ) {

      //   store.dispatch('account-income/churchInfo')
      //     .then(response => {
      //       churchData.value = response.data
      //     })
      //     .catch(error => {
      //       if ([404, 400, 500].includes(error.response.status)) {
      //         churchData.value = undefined
      //       }
      //     }) 

      //   action.value = router.currentRoute.params.action

      //   // fetchReceipts()
      //   recordData.value = router.currentRoute.params.params

      //   const { approvals, atotalAmount } = store.getters['account-income/getApprovals'](router.currentRoute.params.params)
      //   records.value =  approvals
      //   recordData.value.totalAmount = atotalAmount

      //   console.log(router.currentRoute.params)
      //   // console.log(records.value)


      // }

      store.dispatch('account-income/churchInfo')
        .then(response => {
          churchData.value = response.data
        })
      .catch(error => {
            if ([404, 400, 500].includes(error.response.status)) {
              churchData.value = undefined
            }
      }) 

      let getPayments = computed(()=>{
        
        return store
          .getters['account-income/getPayments']((fromDate.value && toDate.value ? [fromDate.value, toDate.value] : date.value))
      })

      let getReceipts = computed(()=>{
        
        return store
          .getters['account-income/getReceipts']((fromDate.value && toDate.value ? [fromDate.value, toDate.value] : date.value))
      })

      const getReceiptsType = computed(() => {

        return [...new Set(store
          .getters['account-income/getReceipts']((fromDate.value && toDate.value ? [fromDate.value, toDate.value] : date.value)).flatMap(x => x.type))]
      })

      const getPaymentsCategory = computed(() => {

        return [...new Set(store
          .getters['account-income/getPayments']((fromDate.value && toDate.value ? [fromDate.value, toDate.value] : date.value)).flatMap(x => x.category))]
      })

      const fetchPayments = (ctx, callback) => {
        store
          .dispatch('account-income/fetchPayments')
          .then(response => {
            // const { payments, total } = response.data

            // callback(payments)
            // totalPayments.value = total
          })
          .catch((err) => {
            console.log(err)
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching payments list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }

      const fetchReceipts = (ctx, callback) => {
        store
          .dispatch('account-income/fetchReceipts')
          .then(response => {
            // const { payments, total } = response.data

            // callback(payments)
            // totalPayments.value = total
          })
          .catch((err) => {
            console.log(err)
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching receipts list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }

      fetchPayments()
      fetchReceipts()
  
      const printIncome = () => {
        window.print()
      }
  
      const resolveIncomeIncomeVariant =  income => {
        if (income === 'cash') return 'primary'
        if (income === 'mobile_money') return 'warning'
        if (income === 'bank') return 'info'
        return 'primary'
      }
  
      const resolveIncomeStatusVariant = status => {
        if (status === 'pending') return 'warning'
        if (status === 'approved') return 'success'
        if (status === 'cancelled') return 'danger'
        return 'secondary'
      }

      const resolveIncomeVariant = income => {
            if (income === 'cash') return 'Cash'
            if (income === 'mobile_money') return 'Mobile Money'
            if (income === 'bank') return 'Bank'
        }

      const rangeOptions = [
        { label: 'Today', value: new Date().toISOString().substring(0,10), date: new Date().toDateString().substring(4) },
        { label: 'Yesterday', value: moment(new Date().toISOString().substring(0,10)).add(-1, 'days').toDate().toISOString().substring(0,10), date: moment(new Date().toISOString().substring(0,10)).add(-1, 'days').toDate().toDateString().substring(4) },
        { label: 'This Month', value: new Date().toISOString().substring(0,7), date: new Date().toDateString().split(' ')[1]+' '+new Date().toDateString().split(' ')[3] },
        { label: 'Last Month', value: moment(new Date().toISOString().substring(0,10)).add(-1, 'months').toDate().toISOString().substring(0,7), date: moment(new Date().toISOString().substring(0,10)).add(-1, 'months').toDate().toDateString().split(' ')[1]+' '+moment(new Date().toISOString().substring(0,10)).add(-1, 'months').toDate().toDateString().split(' ')[3] },
        { label: 'This Year', value: new Date().toISOString().substring(0,4), date: new Date().getFullYear() },
        { label: 'Last Year', value: moment(new Date().toISOString().substring(0,10)).add(-1, 'years').toDate().toISOString().substring(0,4), date: moment(new Date().toISOString().substring(0,10)).add(-1, 'years').toDate().getFullYear() },
      ]

  
      return {
        churchData,
        incomeData,
        printIncome,
        resolveIncomeIncomeVariant,
        resolveIncomeStatusVariant,
        resolveIncomeVariant,
        records,
        recordData,
        action,
        formatAmount,
        getPayments,
        getReceipts,
        getReceiptsType,
        getPaymentsCategory,
        rangeOptions,
        date,
        fromDate,
        toDate
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  @import "~@core/scss/base/pages/app-invoice.scss";
  </style>
  
  <style lang="scss">
  @media print {
  
    // Global Styles
    body {
      background-color: transparent !important;
    }
    nav.header-navbar {
      display: none;
    }
    .main-menu {
      display: none;
    }
    .header-navbar-shadow {
      display: none !important;
    }
    .content.app-content {
      margin-left: 0;
      padding-top: 2rem !important;
    }
    footer.footer {
      display: none;
    }
    .card {
      background-color: transparent;
      box-shadow: none;
    }
    .customizer-toggle {
      display: none !important;
    }
  
    // Income Specific Styles
    .invoice-preview-wrapper {
      .row.invoice-preview {
        .col-md-8 {
          max-width: 100%;
          flex-grow: 1;
        }
  
        .invoice-preview-card {
          .card-body:nth-of-type(2) {
            .row {
                > .col-12 {
                max-width: 50% !important;
              }
  
              .col-12:nth-child(2) {
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                margin-top: 0 !important;
              }
            }
          }
        }
      }
  
      // Action Right Col
      .invoice-actions {
        display: none;
      }
    }
  }
  </style>
  