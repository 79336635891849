import axios from '@/libs/axios'
import { paginateArray, sortCompare } from '@/@fake-db/utils'
import { saveAs } from 'file-saver';
import moment from "moment"

const userData = JSON.parse(localStorage.getItem('userData'))

export default {
  namespaced: true,
  state: {
    income: [],
    receipts: [],
    payments: [],
    total: 0,
    // income: {},
    categories: [],
    totalCategory: 0,
    category: {}
  },
  getters: {
    getIncomes: state => (params) => {
            
      // const results = state.shops.filter(({ name, locationName, type, categories }) => name.toLowerCase().includes(search.toLowerCase()) || locationName.toLowerCase().includes(search.toLowerCase()) || type.toLowerCase().includes(search.toLowerCase()) || categories.find((cat)=> cat.toLowerCase().includes(search.toLowerCase())))

      // const allShops =  results.map(product => product.business)
      // const shops  = [...new Set(allShops)]

      // return results

      // console.log(params)

      const {
        q = '',
        perPage = 10,
        page = 1,
        sortBy = 'date',
        sortDesc = true,
        status = null,
        type = null,
        category = null,
        month = null,
        income = null,
        range = null,
      } = params

      const getRange = (range) => {
        switch (range) {
            case "today":

              return new Date().toISOString().substring(0,10)
            
            break;

            case "yesterday":

              return moment(new Date().toISOString().substring(0,10)).add(-1, 'days').toDate().toISOString().substring(0,10)
            
            break;

            case "this month":

              return new Date().toISOString().substring(0,7)
            
            break;

            case "previous month":

              return moment(new Date().toISOString().substring(0,10)).add(-1, 'months').toDate().toISOString().substring(0,7)

            break;

            case "this year":

              return new Date().toISOString().substring(0,4)
            
            break;

            case "previous year":

              return moment(new Date().toISOString().substring(0,10)).add(-1, 'years').toDate().toISOString().substring(0,4)
            
            break;
        
          default:
            break;
        }
      }

      // console.log(q)

      const queryLowered = q.toLowerCase()
      const filteredData = state.income.filter(
        pay =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (
              pay.amount == queryLowered || pay._incomeDate.toLowerCase().includes(queryLowered) || pay.incomeFor.toLowerCase().includes(queryLowered) 
          ) &&
          pay.type === (type || pay.type) &&
          pay.category === (category || pay.category) &&
          pay.income === (income || pay.income) &&
          pay.month === (month || pay.month) &&
          pay.incomeDate.includes(getRange(range) || pay.incomeDate) &&
          pay.status === (status || pay.status),
      )
      /* eslint-enable  */

      const sortedData = filteredData.sort(sortCompare(sortBy))
      if (sortDesc) sortedData.reverse()

      // console.log(filteredData)
      // console.log(filteredData.reduce((a, b) => a + b.amount, 0))

      return {
          income: paginateArray(sortedData, perPage, page),
          total: filteredData.length,
          totalAmount: filteredData.reduce((a, b) => a + b.amount, 0)
      }
      
    },
    getApprovals: state => (params) => {

      const {
        q = '',
        perPage = 10,
        page = 1,
        sortBy = 'incomeDate',
        sortDesc = true,
        status = null,
        range = null,
      } = params

      const getRange = (range) => {
        switch (range) {
            case "today":

              return new Date().toISOString().substring(0,10)
            
            break;

            case "yesterday":

              return moment(new Date().toISOString().substring(0,10)).add(-1, 'days').toDate().toISOString().substring(0,10)
            
            break;

            case "this month":

              return new Date().toISOString().substring(0,7)
            
            break;

            case "previous month":

              return moment(new Date().toISOString().substring(0,10)).add(-1, 'months').toDate().toISOString().substring(0,7)

            break;

            case "this year":

              return new Date().toISOString().substring(0,4)
            
            break;

            case "previous year":

              return moment(new Date().toISOString().substring(0,10)).add(-1, 'years').toDate().toISOString().substring(0,4)
            
            break;
        
          default:
            break;
        }
      }

      // console.log(getRange(range))

      const queryLowered = q.toLowerCase()
      const filteredData = state.income.filter(
        pay =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (
              pay.amount == queryLowered || pay._incomeDate.toLowerCase().includes(queryLowered) || pay.category.toLowerCase().includes(queryLowered) || pay.type.toLowerCase().includes(queryLowered)
          ) &&
          pay.incomeDate.includes(getRange(range) || pay.incomeDate) &&
          pay.status === (status || pay.status),
      )
      /* eslint-enable  */

      const sortedData = filteredData.sort(sortCompare(sortBy))
      if (sortDesc) sortedData.reverse()

      // console.log(filteredData)
      // console.log(filteredData.reduce((a, b) => a + b.amount, 0))

      return {
          approvals: paginateArray(sortedData, perPage, page),
          atotal: filteredData.length,
          atotalAmount: filteredData.reduce((a, b) => a + b.amount, 0)
      }
      
    },
    getIncomeCategories: state => (params) => {
            
      // const results = state.shops.filter(({ name, locationName, type, categories }) => name.toLowerCase().includes(search.toLowerCase()) || locationName.toLowerCase().includes(search.toLowerCase()) || type.toLowerCase().includes(search.toLowerCase()) || categories.find((cat)=> cat.toLowerCase().includes(search.toLowerCase())))

      // const allShops =  results.map(product => product.business)
      // const shops  = [...new Set(allShops)]

      // return results

      // console.log(params)

      const {
        q = '',
        perPage = 10,
        page = 1,
        sortBy = 'category',
        sortDesc = true,
      } = params

      // console.log(q)

      const queryLowered = q.toLowerCase()
      const filteredData = state.categories.filter(
        pay =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (
              pay.category == queryLowered || pay.description.toLowerCase().includes(queryLowered)  || pay.by.toLowerCase().includes(queryLowered)
          ) 
          // pay.type === (type || pay.type) &&
          // pay.income === (income || pay.income) &&
          // pay.month === (month || pay.month) &&
          // pay.status === (status || pay.status),
      )
      /* eslint-enable  */

      const sortedData = filteredData.sort(sortCompare(sortBy))
      if (sortDesc) sortedData.reverse()

      // console.log(filteredData)
      // console.log(filteredData.reduce((a, b) => a + b.amount, 0))

      return {
          categories: paginateArray(sortedData, perPage, page),
          total: filteredData.length,
      }
      
    },
    getAllIncomeCategories: state => {
      return {
        categories: state.categories,
        total: state.categories.length,
      }
    },
    getAllIncomes: state => {
      return {
        income: state.income,
        total: state.income.length,
      }
    },
    getIncome: state => {
      return state.income
    },
    getReceipts: state => (date) => {

        let data

        if (typeof(date) == 'object') {

          let from = date[0]
          let to = date[1]

          data = state.receipts.filter(receipt =>
              (new Date(receipt.receiptDate).toISOString() >= (from == '' ? new Date().getFullYear()+"-01-01" : new Date(from).toISOString()) )
              && 
              (new Date(receipt.receiptDate).toISOString() <= (to == '' ? new Date().toISOString() : new Date(to).toISOString()) ) 
          )
        } else {
          data = state.receipts.filter(x=>x.receiptDate.includes(date))
        }

        return data
    },
    getPayments: state => (date) => {

        let data

        if (typeof(date) == 'object') {
          let from = date[0]
          let to = date[1]
          
          data = state.payments.filter(pay =>
              (new Date(pay.paymentDate).toISOString() >= (from == '' ? new Date().getFullYear()+"-01-01" : new Date(from).toISOString()) )
              && 
              (new Date(pay.paymentDate).toISOString() <= (to == '' ? new Date().toISOString() : new Date(to).toISOString()) ) 
          )
        } else {
          data = state.payments.filter(x=>x.paymentDate.includes(date))
        }

        return data
    }
  },
  mutations: {
    updateIncomes:  (state, income) => {
      //
      state.income = income
      state.total = income.length
    },
    updateReceipts:  (state, receipts) => {
        //
        state.receipts = receipts
        // state.total = receipts.length
    },
    updatePayments:  (state, payments) => {
      //
      state.payments = payments
    //   state.total = payments.length
    },
    updateIncome: (state, income) => {
      //
      state.income = income
    },
    updateIncomeCategories: (state, categories) => {
      //
      state.categories = categories
      state.totalCategory = categories.length
    },
  },
  actions: {
    fetchReceipts({ commit }, queryParams) {
        return new Promise((resolve, reject) => {
          axios
            .get('/receipts/approved/'+userData.church)
            .then(response => {
  
              //
              commit('updateReceipts', response.data)
              
              resolve(response)
              
            })
            .catch(error => { 
              
              commit('updateReceipts', [])
              
              reject(error)
  
            })
        })
    },
    fetchPayments({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/payments/approved/'+userData.church)
          .then(response => {

            //
            commit('updatePayments', response.data)
            
            resolve(response)
            
          })
          .catch(error => { 
            
            commit('updatePayments', [])
            
            reject(error)

          })
      })
    },
    fetchIncomes({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/income/'+userData.church)
          .then(response => {

            //
            commit('updateIncomes', response.data.filter(re => userData.role !=="admin" ? re.by == userData.fullName : true))
            
            resolve(response)
            
          })
          .catch(error => { 
            
            commit('updateIncomes', [])
            
            reject(error)

          })
      })
    },
    fetchIncome({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/income/${userData.church}/${id}`)
            .then(response => { 

              //
              commit('updateIncome', response.data)
              
              resolve(response) 

            })
            .catch(error => {
              
              // 
              commit('updateIncome', {})

              reject(error)
            
          })
      })
    },
    churchInfo(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/church/${userData.church}`)
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
          })
      })
    },
    addIncome(ctx, { income }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/income', { ...income, church: userData.church, user: userData.fullName })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateIncome(ctx, { income } ) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/income/${userData.church}/${income.id}`, { ...income, church: userData.church })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    approveIncome(ctx, { income }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/income/approvals/${userData.church}`, { ...income, church: userData.church, user: userData.fullName })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelIncome(ctx, { income } ) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/income/cancel/${userData.church}/${income.id}`, { ...income, church: userData.church, user: userData.fullName })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delIncome(ctx, { income }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/income/${userData.church}/${income.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    genIncomeReport(ctx, queryParams) {
      return new Promise((resolve, reject) => {

        const fileName = 'Income_Report_'+(new Date().toISOString().substring(0,10))+'_'+new Date().getTime()

          axios.post(`/income/report/${userData.church}`, {
            fileName: fileName,
            params: queryParams
          }, {
              responseType: 'blob'
          }).then((response) => {
              //
              saveAs(response.data, fileName);
          });
      })
    },
    // income category
    fetchIncomeCategories({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/income/categories/'+userData.church)
          .then(response => {

            //
            commit('updateIncomeCategories', response.data.filter(re => userData.role !=="admin" ? re.by == userData.fullName : true))
            
            resolve(response)
            
          })
          .catch(error => { 
            
            commit('updateIncomes', [])
            
            reject(error)

          })
      })
    },
    addIncomeCategory(ctx, { incomeCategory }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/income/category', { ...incomeCategory, church: userData.church, user: userData.fullName })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateIncomeCategory(ctx, { category, incomeCategory } ) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/income/category/${userData.church}/${category}/${incomeCategory.id}`, { ...incomeCategory, church: userData.church })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delIncomeCategory(ctx, { incomeCategory }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/income/category/${userData.church}/${incomeCategory.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
